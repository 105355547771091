import React from "react";
import { Input, Button } from 'antd';
import { RegEmail, RegName, RegPassword, RegPhone } from "../../config/common/reg";
import { Cookie, getBrowserVersion, _handleTheMainSiteUrlOfBackendResponse, gtag } from "../../actions/util";
import { fetchRequest, getPlatform } from "../../config/Fatch.config";
import { ApiPort } from "../../actions/API";
import Toast from '@/Toast';

let registerText = {
	'm3-001': 'Hoàn Trả 1% Mỗi Ngày',
}
class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			EMAIL: '',
			PWD: '',
			USERNAME: '',
			PHONE: '',


			error_phone: false,
			error_email: false,
			error_name: false,
			error_pwd: false,
			phone_value:'',
			name_value : '',
			pwd_value : '',
			metaRegister: false,
			showPassword: false,
		}
		this.Register = this.Register.bind(this);
		this.toggleShowPassword = this.toggleShowPassword.bind(this);
	}


	/**
	 * 注册
	 */

	RegInputNull() {
		let that = this;
		let { PHONE, PWD, USERNAME } = that.state;
		if(PHONE == ''){
			this.setState({
				error_phone: true,

			},()=>{
				document.getElementsByClassName('phone-hit-error')[0].innerHTML = 'Vui Lòng Điền Số Điện Thoại';
			})
		}
		if(PWD == ''){
			this.setState({
				error_pwd: true,
			},()=>{
				document.getElementsByClassName('pass-hit-error')[0].innerHTML = 'Vui Lòng Điền Mật Khẩu';
			})
		}
		if(USERNAME == ''){
			this.setState({
				error_name: true,
			},()=>{
				document.getElementsByClassName('name-hit-error')[0].innerHTML = 'Vui Lòng Điền Tên Đăng Nhập';
			})
		}
	}

	Register() {
		let that = this;
        const {addAffiliateParamToUrl} = this.props; 
		let { PHONE, PWD, USERNAME , error_name , error_phone , error_pwd } = that.state;
		that.RegInputNull()
		if ((PHONE && !error_phone ) && (PWD && !error_pwd) && (USERNAME && !error_name)) {
			try {
				let params = {
					BlackBoxValue : (global.ioGetBlackbox && global.ioGetBlackbox().blackbox) || '' ,
					E2BlackBoxValue : (global.E2GetBlackbox && global.E2GetBlackbox().blackbox) || '',
					affiliateCode : Cookie.GetCookieKeyValue('CO_affiliate') || '',
					hidBlackbox : (global.ioGetBlackbox && global.ioGetBlackbox().blackbox) || '' ,
					hostName : document.domain,
					mediaCode : Cookie.GetCookieKeyValue('Media') || null,
					mobile : '84-' + PHONE,
					password : PWD,
					referer : Cookie.GetCookieKeyValue('Referer') || document.domain,
					username : USERNAME
				}

				const platform = getPlatform();
				const Apiversion = `?api-version=1.0&brand=JBO&Platform=${platform}`;

				// piwik 追踪
				global.globalGtag &&  global.globalGtag('jbo_QRP', 'Registration form', 'Submit_registration', 'event');

				// ga
				gtag('event', 'Registered', {
					'event_category': 'QRPMobileRegistered',
				});

				Toast.loading('Đang tải...',0)
				fetchRequest(ApiPort.Register, 'POST', params, Apiversion).then(res => {
					if (res) {
						if (res.isSuccess) {
							let updatedUrl = addAffiliateParamToUrl(res.url)
							that.setState({
								metaRegister: true
							}, () => {_handleTheMainSiteUrlOfBackendResponse(updatedUrl)})
							
						} else {
							Toast.hide();
							this.props.openModal(res.message);
						}
					}
				}).catch(error => {
					console.log('服务器错误...',error);
				})
			} catch (error) {
				console.log('服务器错误...', error);
			}
		} else {
			return;
		}

	}

	// 切換顯示密碼
	toggleShowPassword() {
		this.setState(prevState => ({
			showPassword: !prevState.showPassword
		}))
	}

	/**
	 * 改变参数值
	 * @param { String } k 变量
	 * @param { String | Number | any } v 参数
	 */
	setStates(k, v) {
		this.setState({
			[k]: v
		});
	};

	/**
	 * onChange input
	 * @param {*} e
	 * @param {*} type
	 * @returns
	 */
	UserInput(e, type) {
		let val = e.target.value || '';
		this.setStates(type, val);
		this.regInput(e, type);
		return;
		// globalGtag(`Search_withdrawalrecord_Carddetail`);
	}

	/**
	 * input Blur
	 * @param {*} e
	 * @param {*} type
	 */
	inputOnBlur(e, type) {
		this.regInput(e, type);
	}


	/**
	 * 验证Input 方法
	 * @param {*} e
	 * @param {*} type
	 * @returns
	 */
	regInput(e, type) {
		let val = e.target.value || '';
		let that = this;
		let inputMap = {
			'USERNAME': (val) => {
				if (!RegName.test(val)) {
					that.setState({
						error_name: true,
						name_value: val
					}, () => {
						if(val === ''){
							document.getElementsByClassName('name-hit-error')[0].innerHTML = 'Vui Lòng Điền Tên Đăng Nhập';

						} else{
							document.getElementsByClassName('name-hit-error')[0].innerHTML = 'Tên đăng nhập phải bao gồm 6-20 chữ số hoặc ký tự.';
						}

					});
				} else {
					that.setState({
						error_name: false,
						name_value: val
					}, () => {
						global.globalGtag && global.globalGtag('jbo_QRP' , 'Registration form' , 'Username_input' , 'input');
						document.getElementsByClassName('name-hit-error')[0].innerHTML = ''
					});
				}
				return;
			},
			'PWD': (val) => {
				if (!RegPassword.test(val)) {
					that.setState({
						error_pwd: true,
						pwd_value: val
					}, () => {
						if(val === ''){
							document.getElementsByClassName('pass-hit-error')[0].innerHTML = 'Vui Lòng Điền Mật Khẩu'
						} else {
							document.getElementsByClassName('pass-hit-error')[0].innerHTML = 'Mật khẩu không hợp lệ. Vui lòng điền lại.(Mật khẩu phải bao gồm từ 6 đến 20 chữ cái và số, không bao gồm ký hiện, khoảng trống hay gạch dưới ở giữa)'
						}
					})

				} else {
					that.setState({
						error_pwd: false,
						pwd_value: val
					}, () => {
						global.globalGtag && global.globalGtag('jbo_QRP' , 'Registration form' , 'Password_input' , 'input');
						document.getElementsByClassName('pass-hit-error')[0].innerHTML = ''
					})
				}
				return;
			},
			'EMAIL': (val) => {
				if (!RegEmail.test(val)) {
					that.setState({
						error_email: true
					}, () => {
						if (val === '') {
							document.getElementsByClassName('email-hit-error')[0].innerHTML = '电子邮箱不可为空。';
						} else {
							document.getElementsByClassName('email-hit-error')[0].innerHTML = '电子邮箱格式无效';
						}
					})
				} else {
					that.setState({
						error_email: false
					}, () => {
						global.globalGtag && global.globalGtag('jbo_QRP' , 'Registration form' , 'Email_input' , 'input');
						document.getElementsByClassName('email-hit-error')[0].innerHTML = ''
					})
				}
				return;

			},
			'PHONE': (val) => {
				if (!RegPhone.test(val)) {
					that.setState({
						error_phone: true,
						phone_value: val
					}, () => {
						if(val === ''){
							document.getElementsByClassName('phone-hit-error')[0].innerHTML = 'Vui Lòng Điền Số Điện Thoại';
						} else{
							document.getElementsByClassName('phone-hit-error')[0].innerHTML = 'Số điện thoại phải có 9-10 số, vui lòng bỏ số “0” khi nhập thông tin.';
						}
					})
				} else {
					that.setState({
						error_phone: false,
						phone_value: val
					}, () => {
						global.globalGtag && global.globalGtag('jbo_QRP' , 'Registration form' , 'phone_input' , 'input');
						document.getElementsByClassName('phone-hit-error')[0].innerHTML = '';
					})
				}
				return
			}
		}
		inputMap[type](val);
		return;
	}


	render() {
		let { USERNAME, PWD, EMAIL, PHONE, metaRegister, showPassword} = this.state;
		const {addAffiliateParamToUrl, pageName} = this.props; 
		console.log('phone_value',this.state.phone_value);
		return (
			<>
				{metaRegister  && global.fbqTag && fbqTag('CompleteRegistration')}                	
				<div className="register-box web-register" id="mobile-heading">
					<div className="box-head"></div>
					<div className="registration-content">

						<p className="tips">{registerText['m3-001']}</p>

						{/* 用户名 */}
						<div className="flexInput">
							<img className="icon" src="/static/images/m3-001/mb1.png" />
							<Input value={USERNAME} onBlur={(e) => { this.inputOnBlur(e, 'USERNAME') }} type={'name'} onChange={(e) => { this.UserInput(e, 'USERNAME') }} maxLength={14} placeholder="Tên Đăng Nhập"></Input>
						</div>
						{/* 用户名错误显示 */}
						{<p className="name-hit-error"></p>}
						{/* 密码 */}
						<div className="flexInput">
							<img className="icon" src="/static/images/m3-001/mb2.png" />
							<Input 
								className="password-input"
								type={showPassword ? 'text' : 'password'} 
								onBlur={(e) => { this.inputOnBlur(e, 'PWD') }} 
								onChange={(e) => { this.UserInput(e, 'PWD') }} 
								maxLength={20} 
								name="password"
								placeholder="Mật Khẩu" value={PWD}></Input>
							{pageName === 'm3-001' && (
                            <img
                                src={showPassword ? "/static/images/m3-001/eye_opened.png" : "/static/images/m3-001/eye_closed.png"}
                                alt="toggle password visibility"
                                onClick={this.toggleShowPassword}
                                className="pwd-eye-icon"
                            />
                        	)}
						</div>
						{ /* password error */}
						{<p className="pass-hit-error"></p>}

						{/* 电话号码 */}
						<div className="phone-number flexInput">
							<Input className="phone" value={'+84'} disabled={true}></Input>
							<img className="icon icon3" src="/static/images/m3-001/mb3.png" />
							<Input className="phoneNumber" onBlur={(e) => { this.inputOnBlur(e, 'PHONE') }} maxLength={10} name="phoneNumber" id="inputPhone" placeholder="Số Điện Thoại" onChange={(e) => { this.UserInput(e, 'PHONE') }} value={PHONE}></Input>
						</div>
						{ /* email iphone Number */}
						{<p className="phone-hit-error"></p>}

						<p className="tips tips2">*Nhập thông tin chính xác để nhận thưởng</p>
						<div style={{position:'relative'}}>
							<Button className="register-submit btn-download-pc" onClick={this.Register}></Button>
							<a className="loginInBtn" href={addAffiliateParamToUrl(this.props.mainSiteUrl)}>Đăng Nhập</a>
						</div>
					</div>


				</div>



				{/* Mobile Ipad */}
				<div className="mobile-register">
					<img src="/static/images/m3-001/SignUp-v1.png" />

					<div className="flexInput">
						<img className="icon" src="/static/images/m3-001/mb1.png" />
						<Input value={USERNAME} onBlur={(e) => { this.inputOnBlur(e, 'USERNAME') }} type={'name'} onChange={(e) => { this.UserInput(e, 'USERNAME') }} maxLength={14} placeholder="Tên Đăng Nhập"></Input>
					</div>
					{this.state.error_name && this.state.name_value == '' ?
						<p className="name-hit-error">
							Vui Lòng Điền Tên Đăng Nhập
						</p>
					: this.state.error_name && this.state.name_value !== '' ?
						<p className="name-hit-error">
							Tên đăng nhập phải bao gồm 6-20 chữ số hoặc ký tự.
						</p>
					: ''
					}

					{/* 密码 */}
					<div className="flexInput">
						<img className="icon" src="/static/images/m3-001/mb2.png" />
						<Input 
							className="password-input"
							type={showPassword ? 'text' : 'password'} 
							onBlur={(e) => { this.inputOnBlur(e, 'PWD') }} 
							onChange={(e) => { this.UserInput(e, 'PWD') }}
							maxLength={20} 
							name="password" 
							placeholder="Mật Khẩu" 
							value={PWD}></Input>
							{pageName === 'm3-001' && (
								<img
                        		src={showPassword ? "/static/images/m3-001/eye_opened.png" : "/static/images/m3-001/eye_closed.png"}
                        		alt="toggle password visibility"
                        		onClick={this.toggleShowPassword}
                        		className="pwd-eye-icon"
                    		/>
							)}
					</div>
					{this.state.error_pwd && this.state.pwd_value == '' ?
						<p className="pass-hit-error">
							Vui Lòng Điền Mật Khẩu
						</p>
					: this.state.error_pwd && this.state.pwd_value !== '' ?
						<p className="pass-hit-error">
							Mật khẩu không hợp lệ. Vui lòng điền lại.(Mật khẩu phải bao gồm từ 6 đến 20 chữ cái và số, không bao gồm ký hiện, khoảng trống hay gạch dưới ở giữa)
						</p>
					: ''
					}

					{/* 电话号码 */}
					<div className="phone-number flexInput">
						<img className="icon icon3" src="/static/images/m3-001/mb3.png" />
						<Input className="phoneNumber" onBlur={(e) => { this.inputOnBlur(e, 'PHONE') }} maxLength={10} name="phoneNumber" id="inputPhone" placeholder="Số Điện Thoại" onChange={(e) => { this.UserInput(e, 'PHONE') }} value={PHONE}></Input>
					</div>
					{this.state.error_phone && this.state.phone_value == '' ?
						<p className="phone-hit-error">
							Vui Lòng Điền Số Điện Thoại
						</p>
					: this.state.error_phone && this.state.phone_value !== '' ?
						<p className="phone-hit-error">
							Số điện thoại phải có 9-10 số, vui lòng bỏ số “0” khi nhập thông tin.
						</p>
					: ''
					}

					<p className="tips tips2">*Nhập thông tin chính xác để nhận thưởng</p>
					<Button className="register-submit btn-download-pc" onClick={this.Register}></Button>
				</div>



			</>

		)
	}
}


export default Register;
